<!-- eslint-disable vue/valid-template-root -->
<template>
  <PostLoading v-if="pageLoading" />
  <PostError
    v-else-if="pageError"
    :custom-header="false"
    :text="pageError"
  />
  <div v-else>
    <ScrollView
      :loading="loadingStatus"
      :error="pageError"
      :no-more="Boolean(!hasMore)"
      :bottom-distance="0"
      @reachBottom="getAnswerList"
    >
      <div
        v-for="(item, index) in answerList"
        :key="index"
        class="page-ftf-answer-detail"
      >
        <div class="user-info">
          <Avatar
            :size="'s'"
            :avatar="item.replyer && item.replyer.iconUrl"
            :account-type="item.replyer.accountType"
            :vip-type="item.replyer && item.replyer.vipType"
            class="user-info-head"
          />
          <span class="user-info-name">{{
            item.replyer && item.replyer.nickName
          }}</span>
        </div>
        <div class="main-container">
          <div class="question">
            <span class="question-questioner">{{
              item.asker && item.asker.nickName
            }}</span>
            <span class="question-content">: {{ item.questionText }}</span>
          </div>
          <div class="answer">
            <div class="answer-content">
              {{ item.text }}
            </div>
            <FtfAudio
              v-for="(vi,vindex) in item.voiceInfo"
              :key="vindex"
              :audio-src="vi.url"
              :audio-length="Number(vi.duration)"
              :audio-id="String(item.askId + item.answerId+vindex)"
              :is-app="isApp"
              class="answer-audio"
            />
            <ul
              v-if="item.imageInfo && item.imageInfo.length>0"
              class="answer-pic"
            >
              <li
                v-for="(img, imgIndex) in item.imageInfo"
                :key="imgIndex"
                @click="clickPic(item.imageInfo, imgIndex)"
                class="answer-pic-auto-box"
              >
                <img :src="img.url">
              </li>
            <!-- <div
              v-if="item.imageInfo && item.imageInfo.length > 3"
              class="answer-pic-more"
              @click="clickPic(item.imageInfo, 0)"
            >
              <i class="answer-pic-more-icon" />
              <span class="answer-pic-more-num">{{ item.imageInfo && item.imageInfo.length }}</span>
            </div> -->
            </ul>
            <div class="base-info">
              <span class="base-info-time">{{
                item.createTime | formatTime()
              }}</span>
              <div
                @click="clickApplaud(index, item)"
                class="base-info-like"
              >
                <!-- <a class="base-info-like-thumb" /> -->
                <Icon
                  :active="Boolean(item.hasLike)"
                  :name="'applaud'"
                  :size="'s'"
                  class="base-info-like-thumb"
                />
                <span class="base-info-like-num">{{ item.likeNum }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollView>
  <!-- <ImgPreview
    :show="showImgPreview"
    :images="images"
    :active="activePic"
    @change="showImgPreview = false"
  /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import ScrollView from '@/ui/scroll-view/index.vue';
import Avatar from '@/ui/avatar/index.vue';
import Icon from '@/ui/icon/index';
// import ImgPreview from '@/ui/img-preview/index.vue';
import { getAnswerListByAskID, addPraise } from '@/api/face-to-face';
import { imagePreview } from '@/jsbridge';
import reportApi from '@/api/report';
import FtfAudio from './component/ftf-audio/index.vue';

export default {
  components: {
    FtfAudio,
    Avatar,
    Icon,
    PostLoading,
    PostError,
    ScrollView,
  },
  filters: {
    formatTime(time) {
      const numberTime = Number(time);
      const dayGap = 1000 * 60 * 60 * 24;
      const yearGap = 1000 * 60 * 60 * 24 * 366;
      const todayTime = new Date().getTime();
      if (numberTime > 0) {
        const date = new Date(numberTime * 1000);
        const y = date.getFullYear();
        const M = date.getMonth() + 1;
        const d = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        h = h < 10 ? `0${h}` : h;
        m = m < 10 ? `0${m}` : m;
        if (todayTime - date > yearGap) {
          return `${y}-${M}-${d} ${h}:${m}`;
        }
        if (todayTime - date > dayGap) {
          return `${M}-${d} ${h}:${m}`;
        }
        return `${h}:${m}`;
      }
      return '0000-00-00 00:00';
    },
  },
  data() {
    return {
      askId: 0,
      qaLiveId: 0,
      pageLoading: true,
      pageError: false,
      hasMore: 1,
      loadingStatus: false,
      answerList: [],
      images: [],
      showImgPreview: false,
      activePic: 0,
      dialogStatus: true,
      params: {
        userId: 0,
        qaLiveId: 0,
        askId: 0,
        sortType: 0,
        context: '',
      },
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
    }),
  },
  mounted() {
    this.askId = this.$route.query.ask_id;
    this.qaLiveId = this.$route.query.qa_live_id;
    this.reportData({
      pageid: '1508',
      moduleid: 1,
      eventtype: 2,
      extendid: 2,
      ext8: this.qaLiveId,
    }, '策划面对面回答详情页曝光');
    if (this.isApp) {
      this.$store.dispatch('user/getUserInfo')
        .then(() => {
          if (this.userInfo) {
            this.params = {
              userId: this.userInfo && this.userInfo.uid,
              qaLiveId: this.qaLiveId,
              askId: this.askId,
              sortType: 1,
              context: '',
            };
            this.getAnswerList(this.params);
          } else {
            // 未登录执行的操作
            this.params = {
              userId: 0,
              qaLiveId: this.qaLiveId,
              askId: this.askId,
              sortType: 1,
              context: '',
            };
            this.getAnswerList(this.params);
          }
        })
        .catch((err) => {
          this.$logger.err('getUserInfo', err);
        });
    } else {
      this.params = {
        userId: 0,
        qaLiveId: this.qaLiveId,
        askId: this.askId,
        sortType: 1,
        context: '',
      };
      this.getAnswerList(this.params);
    }
  },
  methods: {
    // 获取列表数据
    getAnswerList(params) {
      if (!this.hasMore || this.loadingStatus) {
        return;
      }
      this.loadingStatus = true;
      getAnswerListByAskID(params)
        .then((result) => {
          this.pageLoading = false;
          this.loadingStatus = false;
          if (result.ret === 0) {
            this.hasMore = result.hasMore;
            this.answerList = [
              ...this.answerList,
              ...(result.answerList || []),
            ];
            this.params.context = result.context;
          } else {
            this.pageError = result.msg || '内容加载失败，换个姿势刷新试试';
          }
        })
        .catch(() => {
          this.pageLoading = false;
          this.pageError = '内容加载失败，换个姿势刷新试试';
        });
    },
    // 点赞
    clickApplaud(index, item) {
      if (this.userInfo) {
        this.reportData({
          pageid: '1508',
          moduleid: 14,
          eventtype: 3,
          extendid: 1,
          indexid: index,
          ext8: this.qaLiveId,
        }, '策划面对面回答详情页鼓掌点击');
        const praiseType = [0, 1];
        const params = {
          userId: (this.userInfo && this.userInfo.uid),
          qaLiveId: this.qaLiveId,
          answerId: item.answerId,
          praiseType: praiseType[item.hasLike ? 1 : 0],
        };
        addPraise(params)
          .then((result) => {
            if (result.ret === 0) {
              this.answerList[index].hasLike = !item.hasLike;
              this.answerList[index].likeNum = result.likeNum;
            } else {
              // 出错，需要弹框
              this.$toast(result.msg || '服务器繁忙，请稍后重试');
            }
          })
          .catch((e) => {
            // 出错，需要弹框
            this.$toast(e.data.msg || '服务器繁忙，请稍后重试');
          });
      } else {
        // 拿不到用户数据视为未登录，点赞必须登录
        this.$store.dispatch('user/login')
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            this.pageError = '服务器繁忙，请稍后重试';
          });
      }
    },
    // 过滤前三张
    imageFilter(images) {
      return images.filter((item, index) => index < 3);
    },
    // 点击图片
    clickPic(images, index) {
      // 直接调用终端的图片预览能力
      const option = { index, images };
      imagePreview(option);
      // this.images = images;
      // this.activePic = index;
      // this.showImgPreview = true;
      return undefined;
    },
    // 数据上报
    reportData(params, desc) {
      return reportApi(params, desc);
    },
  },
};
</script>
<style lang="scss" src="./scss/answer-detail.scss"></style>
