<template>
  <div
    v-if="pageReady"
    :class="{status1: activityInfo && Number(activityInfo.curStatus) === 1}"
    class="p-ftf"
  >
    <div
      :style="{opacity: bgOpacity}"
      class="p-ftf---bg"
    >
      <div
        :style="{backgroundImage: `url(${activityInfo.bgUrl})`}"
        class="p-ftf---bg-inner"
      />
      <div class="p-ftf__time">
        <div class="p-ftf__time-date">
          {{ activityInfo.beginTime | formatDate('M月D日') }}
        </div>
        <div class="p-ftf__time-time">
          {{ activityInfo.beginTime | formatTime('h:m') }}-{{ activityInfo.endTime | formatTime('h:m') }}
        </div>
        <div
          :class="{'status-ing': Number(activityInfo.curStatus) === 2,
                   'status-finished': Number(activityInfo.curStatus) === 3}"
          class="p-ftf__time-status"
        />
      </div>
      <div
        ref="orderBtnRef"
        v-if="Number(activityInfo.curStatus) === 1"
        :style="{transform: `scale(${btnScale})`}"
        @click="resumeAppActivity"
        class="p-ftf__btn"
      >
        <p class="p-ftf__btn-text">
          {{ activityInfo.memberCount.toLocaleString() }}预约
        </p>
      </div>
    </div>
    <div
      ref="mainContainerRef"
      class="p-ftf---main"
    >
      <div class="p-ftf__inner">
        <div class="p-ftf__inner---top" />
        <div class="p-ftf__inner---bg" />
        <div
          ref="mainRef"
          class="p-ftf__inner---inner"
        >
          <div
            ref="guestRef"
            v-if="guestObjArr && Array.isArray(guestObjArr)"
            class="p-ftf__inner-guest"
          >
            <div class="p-ftf__inner-guest-bg">
              <div class="p-ftf__inner-guest-bg-top" />
              <div class="p-ftf__inner-guest-bg-middle" />
              <div class="p-ftf__inner-guest-bg-bottom" />
            </div>
            <div class="p-ftf__inner-guest--inner">
              <div class="p-ftf__inner-guest-list">
                <div
                  v-for="(item, index) in guestObjArr"
                  :key="item.userId"
                  @click="resumeAppActivity"
                  class="guest-item"
                >
                  <Avatar
                    :size="'xl'"
                    :avatar="item.userIcon"
                    :account-type="item.accountType"
                    :vip-type="item.vipType"
                    class="guest-item__avatar"
                  />
                  <div class="guest-item__cont">
                    <div class="guest-item__cont-title">
                      <div class="guest-item__cont-title-l">
                        <p class="guest-item__cont-title-l-name">
                          {{ item.userName }}
                        </p>
                        <div
                          v-if="item.audioUrl !== '' && item.audioDuration > 0"
                          @click.stop="startPlay(item, index)"
                          class="guest-item__cont-title-l-audio"
                        >
                          <span
                            :class="{'status-playing': item.isPlaying}"
                            class="guest-item__cont-title-l-audio-img"
                          />
                          <span class="guest-item__cont-title-l-audio-text">{{ item.remainTime }}</span>
                        </div>
                      </div>
                      <Button
                        :type="'primary'"
                        :size="'s'"
                        :width="50"
                        :title="'关注'"
                        @click="onUserFollowClick(item.userId)"
                        class="guest-item__cont-title-btn"
                      />
                    </div>
                    <p class="guest-item__cont-text">
                      {{ item.descr }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="Number(activityInfo.curStatus) === 3 && Array.isArray(activityList) && activityList.length > 0"
            class="p-ftf__inner-more"
          >
            <div class="p-ftf__inner-more-title">
              更多看点
            </div>
            <div class="p-ftf__inner-more---container">
              <ul
                :style="{width: `${activityList.length * 215}px`}"
                class="p-ftf__inner-more---container-inner"
              >
                <li
                  v-for="(item, index) in activityList"
                  :key="index"
                  @click="onActivityClick(item)"
                  class="p-ftf__inner-more-item"
                >
                  <div class="p-ftf__inner-more-item-img">
                    <span
                      :style="{backgroundImage: `url(${item.iconUrl})`}"
                      class="p-ftf__inner-more-item-img-main"
                    />
                  </div>
                  <div class="p-ftf__inner-more-item-cont">
                    {{ item.title }}
                  </div>
                  <span
                    v-if="Number(item.curStatus) === 1"
                    class="p-ftf__inner-more-item-label"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div
            ref="tabBarRef"
            :style="{height: (!isStickySupport && tabBarHeight) ? `${tabBarHeight}px` : 'auto'}"
            class="p-ftf__inner-tab-bar"
          >
            <div
              :class="{sticky: showStickyClassStatus}"
              class="p-ftf__inner-tab-bar-content"
            >
              <TabBar
                v-model="currentTab"
                :level="2"
                :tab-list="tabList"
                @tabChange="onTabChange"
              />
            </div>
          </div>
          <div
            :style="{minHeight: minTabContHeight}"
            class="p-ftf__inner-tab-cont"
          >
            <div
              ref="scrollContent"
              v-show="currentTabIndex === 0"
              class="p-ftf__inner-tab-cont-item"
            >
              <div
                class="tab-cont-item"
              >
                <div
                  v-if="answerLoading"
                  class="tab-cont-item__loading"
                >
                  正在加载中...
                </div>
                <div
                  v-if="!answerLoading && !answerList"
                  @click="onLoadAnswerError"
                  class="tab-cont-item__error"
                >
                  加载失败，请点击重试
                </div>
                <template v-if="answerList && Array.isArray(answerList)">
                  <ul
                    v-if="answerList.length > 0"
                    class="tab-cont-item__answer"
                  >
                    <li
                      v-for="(item, i) in answerList"
                      :key="i"
                      class="tab-cont-item__answer-item"
                    >
                      <QaItem
                        :class="{'border0': i === answerList.length - 1}"
                        :type="'answer'"
                        :host-user-id="Number(activityInfo.hostUserId)"
                        :qa-data="item"
                        @resumeAppActivity="resumeAppActivity"
                        @addAudioObj="onAddAudioObj"
                        @audioPlay="onAudioPlay"
                        @audioClick="onAudioClick"
                      />
                    </li>
                    <li
                      @click="onMoreClick"
                      class="tab-cont-item__answer-more"
                    >
                      <div class="more-text">
                        点击查看更多
                      </div>
                      <div class="more-icon" />
                    </li>
                  </ul>
                  <div
                    v-if="answerList.length === 0"
                    class="tab-cont-item__empty"
                  >
                    <div class="tab-cont-item__empty-img" />
                    <div class="tab-cont-item__empty-text">
                      暂无更多信息~
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div
              ref="scrollContent2"
              v-show="currentTabIndex === 1"
              class="p-ftf__inner-tab-cont-item"
            >
              <div
                class="tab-cont-item"
              >
                <div
                  v-if="questionLoading"
                  class="tab-cont-item__loading"
                >
                  正在加载中...
                </div>
                <div
                  v-if="!questionLoading && !questionList"
                  @click="onLoadQuestionError"
                  class="tab-cont-item__error"
                >
                  加载失败，请点击重试
                </div>
                <template v-if="questionList && Array.isArray(questionList)">
                  <div
                    class="tab-cont-item__filter"
                  >
                    <div
                      @click="onFilterClick"
                      class="tab-cont-item__filter-sort"
                    >
                      <div class="sort-label">
                        排序：
                      </div>
                      <div class="sort-text">
                        <div class="sort-text-main">
                          {{ questionSortTypeSelected.text }}
                        </div>
                        <div class="sort-text-icon" />
                      </div>
                    </div>
                    <transition
                      name="fade"
                      enter-class="fade-in-enter"
                      enter-active-class="fade-in-active"
                      leave-class="fade-out-enter"
                      leave-active-class="fade-out-active"
                    >
                      <div
                        v-show="showSortTypeListStatus"
                        class="tab-cont-item__filter-popup"
                      >
                        <div
                          v-for="(item, index) in questionSortTypeList"
                          :key="index"
                          :class="{active: questionSortTypeSelected['value'] === item['value']}"
                          @click="changeQuestionSortType(item)"
                          class="tab-cont-item__filter-popup-item"
                        >
                          <div class="icon-check" />
                          <div class="text">
                            {{ item['text'] }}问题
                          </div>
                        </div>
                      </div>
                    </transition>
                    <div
                      v-show="showSortTypeListStatus"
                      @touchstart.stop="onShadowTouchStartHandler"
                      @touchend.stop="onShadowTouchEndHandler"
                      @click.stop="onShadowClickHandler"
                      class="tab-cont-item__filter-shadow"
                    />
                  </div>
                  <ul
                    v-if="questionList.length > 0"
                    class="tab-cont-item__answer"
                  >
                    <li
                      v-for="(item, i) in questionList"
                      :key="i"
                      class="tab-cont-item__answer-item"
                    >
                      <QaItem
                        :class="{'border0': i === questionList.length - 1}"
                        :type="'question'"
                        :qa-data="item"
                        @resumeAppActivity="resumeAppActivity"
                        @agreeClick="onAgreeClick"
                      />
                    </li>
                    <li
                      @click="onMoreClick"
                      class="tab-cont-item__answer-more"
                    >
                      <div class="more-text">
                        点击查看更多
                      </div>
                      <div class="more-icon" />
                    </li>
                  </ul>
                  <div
                    v-if="questionList && questionList.length === 0"
                    class="tab-cont-item__empty"
                  >
                    <div class="tab-cont-item__empty-img" />
                    <div class="tab-cont-item__empty-text">
                      暂时没有提问哦~
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            class="p-ftf__inner-ask"
          >
            <div class="p-ftf__inner-ask--inner">
              <Button
                :type="'primary'"
                :size="'l'"
                :icon="'write'"
                :title="'我要提问'"
                @click="onAskClick"
              />
            </div>
          </div>
          <div
            v-if="false"
            @click="scrollToNew"
            class="p-ftf__inner-notice"
          >
            <div class="p-ftf__inner-notice---bg" />
            <div class="p-ftf__inner-notice---inner">
              <div class="p-ftf__inner-notice-icon" />
              <div class="p-ftf__inner-notice-text">
                6条新消息
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import launchApp from '@/util/launchApp';
// api
import {
  getActivityInfo, getActivityList, getQuestionList, getAnswerList,
} from '@/api/face-to-face';
import reportApi, { reportEnum } from '@/api/report';
// util
import { throttle, cssSupport, scrollTo } from '@/util/util';
// UI组件
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button/index.vue';
import TabBar from '@/ui/tab/bar.vue';

// 业务组件
import QaItem from './component/qa-item/index.vue';

const questionSortTypeList = [
  {
    text: '最新',
    value: 2,
  },
  {
    text: '最热',
    value: 1,
  },
];

export default {
  components: {
    Avatar,
    Button,
    TabBar,
    QaItem,
  },
  filters: {
    formatDate(time, str) {
      const oDate = new Date(Number(time) * 1000);
      const M = oDate.getMonth() + 1;
      const D = oDate.getDate();
      return str.replace(/(M|D)/g, t => ({ M, D }[t]));
    },
    formatTime(time, str) {
      const oDate = new Date(Number(time) * 1000);
      let h = oDate.getHours();
      let m = oDate.getMinutes();
      let s = oDate.getSeconds();
      h = h < 10 ? `0${h}` : h;
      m = m < 10 ? `0${m}` : m;
      s = s < 10 ? `0${s}` : s;
      return str.replace(/(h|m|s)/g, t => ({ h, m, s }[t]));
    },
  },
  data() {
    return {
      activityId: 1,
      activityInfo: null,
      activityList: null,
      guestObjArr: [],
      bgOpacity: 1,
      btnScale: 1,
      showStickyClassStatus: false,
      isStickySupport: true,
      isTabScroll: false,
      tabBarTop: 0,
      tabBarHeight: 0,
      minTabContHeight: 'auto',
      currentTab: 1,
      currentTabIndex: 0,
      tabList: [
        {
          id: 1,
          text: '正在热聊',
        },
        {
          id: 2,
          text: '全部问题',
        },
      ],
      // 全部答案列表
      answerLoading: true,
      answerList: null,
      questionLoading: true,
      questionList: null,
      answerAudioArr: [],
      // 全部问题筛选
      showSortTypeListStatus: false,
      changeSortTypeListFlag: true,
      questionSortTypeList,
      questionSortTypeSelected: questionSortTypeList[1],
    };
  },
  computed: {
    pageReady() {
      const condition1 = (Number(this.activityInfo.curStatus) === 3 && Array.isArray(this.activityList));
      const condition2 = condition1 || Number(this.activityInfo.curStatus) !== 3;
      return this.activityInfo && condition2;
    },
  },
  watch: {
    currentTab(val) {
      const scrollElement = document.documentElement.clientHeight ? document.documentElement : document.body;
      const { scrollTop } = scrollElement;
      let i = 1;
      this.tabList.some((item, index) => {
        if (item.id === val) {
          i = index;
          return true;
        }
        return false;
      });
      this.currentTabIndex = '';
      if (scrollTop + 2 > this.tabBarTop) {
        scrollElement.scrollTop = this.tabBarTop;
      }
      setTimeout(() => {
        if (i === 0) {
          this.reportData({
            moduleid: 2,
            eventtype: 2,
            extendid: 1,
          }, '策划面对面分享h5-正在热聊曝光');
        } else {
          this.reportData({
            moduleid: 2,
            eventtype: 2,
            extendid: 2,
          }, '策划面对面分享h5-全部问题曝光');
        }
        this.currentTabIndex = i;
      }, 16);
      return false;
    },
  },
  created() {
    if (!cssSupport('position', ['sticky', '-webkit-sticky'])) this.isStickySupport = false;
    this.currentTab = 1;
    this.reportData({
      moduleid: 2,
      eventtype: 2,
      extendid: 1,
    }, '策划面对面分享h5-正在热聊曝光');
    this.activityId = this.$route.query.activityId;
    if (typeof this.activityId === 'undefined' || Number(this.activityId) <= 0) {
      this.$toast({
        message: '( T﹏T ) 未找到相关活动',
        duration: 100000,
      });
    } else {
      this.initPageData();
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      // 用户离开了当前页面
      if (document.visibilityState === 'hidden') {
        this.onPageHidden();
      }

      // 用户打开或回到页面
      if (document.visibilityState === 'visible') {
        this.onPageShow();
      }
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    // 数据上报函数
    reportData(params, desc) {
      const obj = {
        indexid: 1,
        pageid: reportEnum.pageid.FACE_TO_FACE_H5,
        ...params,
      };
      reportApi(obj, desc);
    },
    onTabChange(val, item, index) {
      if (index === 0) {
        this.reportData({
          moduleid: 2,
          eventtype: 3,
          extendid: 1,
        }, '策划面对面分享h5-正在热聊点击');
      } else {
        this.reportData({
          moduleid: 2,
          eventtype: 3,
          extendid: 2,
        }, '策划面对面分享h5-全部问题点击');
      }
    },
    // 页面dom元素ready
    bindMountedFn() {
      this.reportData({
        moduleid: 1,
        eventtype: 1,
        extendid: 1,
      }, '策划面对面分享h5页面pv');
      this.tabBarTop = this.$refs.tabBarRef.getBoundingClientRect().top;
      this.tabBarHeight = this.$refs.tabBarRef.getBoundingClientRect().height;
      this.minTabContHeight = `${document.documentElement.clientHeight - this.tabBarHeight}px`;
      // console.log(`this.tabBarTop: ${this.tabBarTop}`);
      const mainToTop = this.$refs.mainRef.getBoundingClientRect().top;
      const orderBtnHeight = this.$refs.orderBtnRef && this.$refs.orderBtnRef.getBoundingClientRect().height;
      const changeTop = 100;
      this.scrollHandler = throttle(() => {
        const st = document.documentElement.scrollTop || document.body.scrollTop;
        // 头部透明度
        (() => {
          if (st < changeTop) {
            this.bgOpacity = 1;
            return false;
          } if (st < mainToTop) {
            const s1 = st - 100;
            const s2 = mainToTop - 100;
            this.bgOpacity = ((s2 - s1) / s2).toFixed(2);
            return false;
          }
          this.bgOpacity = 0;
          return false;
        })();
        if (orderBtnHeight) {
          // 按钮
          (() => {
            const changeHeight = 15;
            if ((st - changeHeight) < orderBtnHeight) {
              const scale = ((orderBtnHeight - (st - changeHeight)) / orderBtnHeight).toFixed(2);
              this.btnScale = scale > 1 ? 1 : scale;
            } else {
              this.btnScale = 0;
            }
          })();
        }
        if (!this.isStickySupport) {
          this.showStickyClassStatus = st >= this.tabBarTop;
        }
      }, 16);
      window.addEventListener('scroll', this.scrollHandler);
    },
    // 初始化页面数据
    initPageData() {
      this.getActivityInfo();
      this.getActivityList();
      this.getAnswerList();
      this.getQuestionList();
    },
    // 获取活动信息
    getActivityInfo() {
      getActivityInfo({
        activityId: this.activityId,
      })
        .then((res) => {
          if (res.ret === 0) {
            const activityInfo = res.info;
            this.renderTab({ activityInfo });
            const guestObjArr = this.initAudioObj(activityInfo);
            this.renderPage({ activityInfo, guestObjArr });
          } else {
            throw res.ret;
          }
        })
        .catch((err) => {
          this.$logger.err('getActivityInfo', err);
          this.$toast({
            message: '( T﹏T ) 活动繁忙，请稍候重试',
            duration: 100000,
          });
        });
    },
    initAudioObj(activityInfo) {
      const guestObjArr = [];
      activityInfo.guestUser.forEach((item) => {
        const o = document.createElement('audio');
        o.src = item.audioUrl;
        guestObjArr.push({
          ...item,
          audioObj: o,
          remainTime: this.formatAudioSecond(item.audioDuration),
          isPlaying: false,
        });
      });
      guestObjArr.forEach((item, index) => {
        item.audioObj.addEventListener('play', () => {
          // console.log('play');
          this.$set(this.guestObjArr, index, {
            ...this.guestObjArr[index],
            isPlaying: true,
            remainTime: this.formatAudioSecond(item.audioObj.duration),
          });
        });
        item.audioObj.addEventListener('playing', () => {
          // console.log('playing');
          this.$set(this.guestObjArr, index, {
            ...this.guestObjArr[index],
            isPlaying: true,
            remainTime: this.formatAudioSecond(item.audioObj.duration),
          });
        });
        item.audioObj.addEventListener('timeupdate', () => {
          this.$set(this.guestObjArr, index, {
            ...this.guestObjArr[index],
            remainTime: this.formatAudioSecond(item.audioObj.duration - item.audioObj.currentTime),
          });
        });
        item.audioObj.addEventListener('pause', () => {
          // console.log('pause');
          // eslint-disable-next-line no-param-reassign
          item.audioObj.currentTime = 0;
          this.$set(this.guestObjArr, index, {
            ...this.guestObjArr[index],
            isPlaying: false,
            remainTime: this.formatAudioSecond(item.audioObj.duration),
          });
        });
        item.audioObj.addEventListener('ended', () => {
          // console.log('ended');
          // eslint-disable-next-line no-param-reassign
          item.audioObj.currentTime = 0;
          this.$set(this.guestObjArr, index, {
            ...this.guestObjArr[index],
            isPlaying: false,
            remainTime: this.formatAudioSecond(item.audioObj.duration),
          });
        });
        item.audioObj.addEventListener('error', () => {
          // console.log('error');
          // eslint-disable-next-line no-param-reassign
          item.audioObj.currentTime = 0;
          this.$set(this.guestObjArr, index, {
            ...this.guestObjArr[index],
            isPlaying: false,
            remainTime: '0:00',
            isError: true,
          });
        });
      });
      return guestObjArr;
    },
    getActivityList() {
      getActivityList({
        timeLine: 0,
        count: 6,
      })
        .then((res) => {
          if (res.ret === 0) {
            const activityList = res.infoList;
            this.renderPage({ activityList });
          } else {
            throw res.ret;
          }
        })
        .catch((err) => {
          this.$logger.err('getActivityList', err);
        });
    },
    // 获取答案列表
    getAnswerList() {
      this.answerList = null;
      this.answerLoading = true;
      getAnswerList({
        qaLiveId: this.activityId,
      })
        .then((res) => {
          if (res.ret === 0) {
            // console.log(res);
            this.answerList = res.answerList.filter(item => !!item.replyer);
          } else {
            throw res.ret;
          }
        })
        .catch((err) => {
          this.$logger.err('getAnswerList', err);
        })
        .finally(() => {
          this.answerLoading = false;
        });
    },
    onLoadAnswerError() {
      this.getAnswerList();
    },
    renderTab({ activityInfo } = {}) {
      let tabText = '';
      if (activityInfo.curStatus === 1) {
        tabText = '正在预热';
      } else if (activityInfo.curStatus === 2) {
        tabText = '正在热聊';
      } else if (activityInfo.curStatus === 3) {
        tabText = '精彩回顾';
      } else {
        tabText = '敬请期待';
      }
      this.$set(this.tabList, 0, {
        ...this.tabList[0],
        text: tabText,
      });
    },
    renderPage({
      activityInfo,
      guestObjArr,
      activityList,
    } = {}) {
      if (activityInfo) this.activityInfo = activityInfo;
      if (guestObjArr) this.guestObjArr = guestObjArr;
      if (activityList) this.activityList = activityList;
      if (this.pageReady) {
        this.$nextTick(() => {
          this.bindMountedFn();
        });
      }
    },
    // 获取问题列表
    getQuestionList(type) {
      const typeComputed = typeof type === 'undefined' ? this.questionSortTypeSelected.value : type;
      this.questionList = null;
      this.questionLoading = true;
      getQuestionList({
        qaLiveId: this.activityId,
        type: typeComputed,
      })
        .then((res) => {
          if (res.ret === 0) {
            this.questionList = res.questionList.filter(item => !!item.asker);
          } else {
            throw res.ret;
          }
        })
        .finally(() => {
          this.questionLoading = false;
        });
    },
    onLoadQuestionError() {
      this.getQuestionList();
    },
    // 切换问题筛选方式
    changeQuestionSortType(item) {
      // console.log(item);
      // console.log(this.questionSortTypeSelected);
      if (item.value !== this.questionSortTypeSelected.value) {
        this.questionSortTypeSelected = item;
        this.showSortTypeListStatus = false;
        this.getQuestionList(item.value);
      }
    },
    // 格式化音频长度
    formatAudioSecond(seconds) {
      const intSeconds = Math.floor(Number(seconds));
      if (intSeconds > 0) {
        let m = 0;
        let s = 0;
        if (intSeconds > 60 * 9 + 59) {
          m = 9;
          s = 59;
        } else {
          m = Math.floor(intSeconds / 60);
          s = intSeconds % 60;
          s = s < 10 ? `0${s}` : s;
        }
        return `${m}:${s}`;
      }
      return '0:00';
    },
    // 点击用户关注按钮
    onUserFollowClick() {
      this.resumeAppActivity();
    },
    // 点击切换排序方式
    onFilterClick() {
      if (!this.changeSortTypeListFlag) return false;
      this.showSortTypeListStatus = !this.showSortTypeListStatus;
      return undefined;
    },
    // 点击提出问题
    onAskClick() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 5,
      }, '策划面对面分享h5 我要提问按钮点击');
      this.resumeAppActivity();
    },
    // 点击查看更多
    onMoreClick() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
      }, '策划面对面分享h5点击查看更多按钮');
      this.resumeAppActivity();
    },
    // 唤醒app
    resumeAppActivity({ activityId } = {}) {
      const targetActivityId = typeof activityId !== 'undefined' ? activityId : this.activityId;
      launchApp({ scheme: `tgc://qa_live/home?activityId=${targetActivityId}` });
    },
    onActivityClick({ activityId }) {
      this.resumeAppActivity({ activityId });
    },
    // 滑动，暂时废弃
    scrollToNew() {
      scrollTo({
        scrollElement: document.documentElement.clientHeight ? document.documentElement : document.body,
        scrollTop: this.$refs.mainContainerRef.clientHeight,
        animationType: 'easeIn',
      });
    },
    // 播放嘉宾音频
    startPlay({ userId }) {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 2,
      }, '策划面对面分享h5 嘉宾介绍语音点击');
      const isCurEqualToTar = this.guestObjArr.some((item) => {
        if (item.isPlaying) {
          item.audioObj.pause();
          if (item.userId === userId) return true;
        }
        return false;
      });
      if (!isCurEqualToTar) {
        this.guestObjArr.forEach((item) => {
          item.audioObj.pause();
        });
        this.answerAudioArr.forEach((item) => {
          item.audioObj.pause();
        });
        const curItem = this.guestObjArr.filter(item => item.userId === userId)[0];
        if (curItem.isError) {
          this.$toast('( T﹏T ) 音源无法正常播放 ...');
          return false;
        }
        curItem.isPlaying = true;
        // curItem.audioObj.currentTime = 230;
        curItem.audioObj.play();
      }
      return false;
    },
    // 点击回答中的音频
    onAudioClick() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 3,
      }, '策划面对面分享h5 嘉宾回答语音点击');
    },
    // 点击同问
    onAgreeClick() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 4,
      }, '策划面对面分享h5 同问数点击');
      this.resumeAppActivity();
    },
    // 收集页面内播放元素
    onAddAudioObj(obj) {
      this.answerAudioArr.push(obj);
    },
    // 页面内元素播放监听
    onAudioPlay(audioId) {
      this.guestObjArr.forEach((item) => {
        if (item.isPlaying) {
          item.audioObj.pause();
        }
      });
      this.answerAudioArr.forEach((item) => {
        if (item.audioId !== audioId) {
          item.audioObj.pause();
        }
      });
    },
    // 筛选背景触摸
    onShadowTouchStartHandler() {
      // console.log(`touchstart: ${Date.now()}`);
      this.showSortTypeListStatus = false;
      this.changeSortTypeListFlag = false;
      setTimeout(() => {
        this.changeSortTypeListFlag = true;
      }, 300);
    },
    // 筛选背景触摸
    onShadowTouchEndHandler() {
      // console.log(`touchend: ${Date.now()}`);
    },
    // 筛选背景点击
    onShadowClickHandler(e) {
      // console.log(`click: ${e}, ${Date.now()}`);
      e.preventDefault();
      this.showSortTypeListStatus = false;
    },
    onPageHidden() {
      this.guestObjArr.forEach((item) => {
        item.audioObj.pause();
      });
      this.answerAudioArr.forEach((item) => {
        item.audioObj.pause();
      });
    },
    onPageShow() {
      // ...
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
