<template>
  <div
    v-if="audioSrc"
    class="pc-ftf-audio-box"
  >
    <div class="pc-ftf-audio-box---container">
      <i
        :class="{ 'button-pause': isPlaying, 'button-start': !isPlaying }"
        @click="onPlayClick"
        class="pc-ftf-audio-box-play"
      />
      <div
        ref="audioBox"
        :class="'bar' + classWidth"
        @click="jumpAudio"
        @touchstart="touchStart"
        @touchmove="touchBar"
        @touchend="touchEnd"
        class="pc-ftf-audio-box-wave"
      >
        <div
          class="wave-remain"
        />
        <div
          :style="{ width: isPlaying ? playProgress * 100 + '%' : 0}"
          class="wave-bg"
        />
        <div
          ref="pointer"
          :style="{ left: pointerLeft }"
          class="wave-play"
        />
      </div>
      <div class="pc-ftf-audio-box-time">
        {{ totalLength }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { send } from '@/jsbridge';

const Bus = new Vue();
export default {
  props: {
    audioId: {
      type: String,
      default: '',
    },
    audioSrc: {
      type: String,
      default: '',
    },
    audioLength: {
      type: Number,
      default: 0,
    },
    isApp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audioObj: null,
      isPlaying: false,
      isError: false,
      totalLength: '',
      classWidth: '',
      playProgress: 0,
      pointerLeft: 0,
      isTouching: false,
      trueAudioLength: 0,
    };
  },
  created() {
    let duration = 0;
    if (this.audioLength) {
      duration = Math.floor(this.audioLength);
    } else {
      duration = 0;
    }
    this.classWidth = this.getClassWidth(duration);
    this.totalLength = this.getTotalLength(duration);
  },
  mounted() {
    this.getElementStyle();
    const audioObj = document.createElement('audio');
    audioObj.src = this.audioSrc;
    this.audioObj = audioObj;
    this.audioObj.addEventListener('play', () => {
      if (this.audioObj && this.audioObj.duration) {
        this.trueAudioLength = this.audioObj.duration;
      }
    });
    this.audioObj.addEventListener('timeupdate', () => {
      this.audioTimeUpdate();
    });
    this.audioObj.addEventListener('ended', () => {
      this.isPlaying = false;
      this.playProgress = 0;
      this.audioObj.currentTime = 0;
    });
    this.audioObj.addEventListener('pause', () => {
      this.isPlaying = false;
    });
    this.audioObj.addEventListener('error', () => {
      this.isError = true;
    });
    this.$emit('addAudioObj', {
      audioId: this.audioId,
      audioObj: this.audioObj,
    });
    Bus.$on('ftfplay', (target) => {
      if (target !== this.audioId) {
        this.audioObj.pause();
      }
    });
  },
  methods: {
    getClassWidth(duration) {
      if (duration < 10) {
        return 10;
      }
      if (duration < 30) {
        return 30;
      }
      return 60;
    },
    getTotalLength(duration) {
      const min = Math.floor(duration / 60);
      let sec = Math.floor(duration % 60);
      if (duration < 60) {
        if (sec === 0) {
          // 小于1秒的音频
          sec = 1;
        }
        return `${sec}"`;
      }
      if (sec === 0) {
        sec = '00';
      }
      return `${min}'${sec}"`;
    },
    getElementStyle() {
      const boxLeft = this.$refs.audioBox.getBoundingClientRect().left;
      const boxWidth = this.$refs.audioBox.getBoundingClientRect().width;
      const pointerWidth = this.$refs.pointer.getBoundingClientRect().width;
      this.styleObj = {
        boxLeft,
        boxWidth,
        pointerWidth,
      };
    },
    // 播放、停止音频
    onPlayClick() {
      this.$emit('audioClick', this.audioId);
      if (this.isError) {
        this.$toast('( T﹏T ) 音源无法正常播放 ...');
        return false;
      }
      // 这里要检查一下页面内的音频
      if (this.isPlaying) {
        this.audioObj.pause();
        this.isPlaying = false;
      } else {
        if (this.isApp) {
        // 在app内才发出通知 关掉其他的
          send({
            noticeId: 'audio_player',
            noticeEvent: 'start',
            noticeParams: {},
          });
        }
        this.$emit('audioPlay', this.audioId);
        Bus.$emit('ftfplay', this.audioId);
        setTimeout(() => {
          // 通知页面把其他音频关掉
          this.audioObj.play();
          this.isPlaying = true;
        });
      }
      return undefined;
    },
    // 点击进度条
    jumpAudio(e) {
      // 获取进度条左边坐标、总长度， 在获取点击坐标计算百分比
      const { boxLeft, boxWidth, pointerWidth } = this.styleObj;
      const clickPageX = e.pageX;
      if (clickPageX - boxLeft > (boxWidth - pointerWidth)) {
        this.playProgress = 1;
      } else {
        this.playProgress = (clickPageX - boxLeft) / (boxWidth - pointerWidth);
      }
      this.pointerLeft = `${(boxWidth - pointerWidth) * this.playProgress}px`;
      this.changeAudioProgress();
      return undefined;
    },
    touchStart() {
      this.isTouching = true;
    },
    // 挪动进度条
    touchBar(e) {
      // 获取进度条左边坐标、总长度， 在获取点击坐标计算百分比
      const { boxLeft, boxWidth, pointerWidth } = this.styleObj;
      const clickPageX = e.touches[0].pageX;
      if (clickPageX - boxLeft > (boxWidth - pointerWidth)) {
        this.playProgress = 1;
      } else if (clickPageX - boxLeft <= 0) {
        this.playProgress = 0;
      } else {
        this.playProgress = (clickPageX - boxLeft) / (boxWidth - pointerWidth);
      }
      this.pointerLeft = `${(boxWidth - pointerWidth) * this.playProgress}px`;
      return undefined;
    },
    touchEnd() {
      this.isTouching = false;
      this.changeAudioProgress();
    },
    audioTimeUpdate() {
      if (!this.isTouching) {
        const { boxWidth, pointerWidth } = this.styleObj;
        const { currentTime } = this.audioObj;
        const { duration } = this.audioObj;
        this.playProgress = parseFloat((currentTime / duration).toFixed(2));
        this.pointerLeft = `${(boxWidth - pointerWidth) * this.playProgress}px`;
      }
    },
    changeAudioProgress() {
      let t = this.audioLength;
      if (this.trueAudioLength) {
        t = this.trueAudioLength;
      } else if (this.audioObj && this.audioObj.duration) {
        t = this.audioObj.duration;
      }
      if (!t || t <= 0) return false;
      const targetTime = t * this.playProgress;
      this.changeAudioTime(targetTime);
      return undefined;
    },
    // 改变播放时间
    changeAudioTime(val) {
      this.audioObj.currentTime = val;
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
