<template>
  <div class="ui-tab-bar">
    <div
      ref="scrollContainer"
      :style="{...styles.tabContainer,
               height: heightComputed,
               overflowX: modeComputed === 'equal' ? 'scroll' : 'hidden'}"
      :showsHorizontalScrollIndicator="showsHorizontalScrollIndicator"
      @scroll="onScroll"
      class="ui-tab-bar--container"
    >
      <div
        :style="{...styles.tabInner,
                 flex: modeComputed === 'equal' ? 0 : 1,
                 justifyContent: justifyContentComputed }"
        class="ui-tab-bar--container-inner"
      >
        <div
          v-for="(tab, index) in tabList"
          :key="tab[valueKey]"
          :style="{...styles.tabItemContainer,
                   height: heightComputed,
                   marginRight: getMarginRight(index)}"
          @click.stop="onTabChange(tab, index)"
          @layout="onItemLayout($event,index)"
          class="ui-tab-bar__item"
        >
          <div
            :style="{...createTabItemInnerStyle(tab, index),}"
            class="ui-tab-bar__item-inner"
          >
            <img
              v-if="level === 3 && getSelectStatus(tab, index)"
              :src="imageBtnBg"
              :style="styles.level3Bg"
            >
            <div
              v-if="getSelectStatus(tab, index)"
              :style="strongDecoContainer"
              class="ui-tab-bar__item-inner-deco"
            >
              <div
                :style="strongDecoInner"
                class="ui-tab-bar__item-inner-deco-inner"
              >
                <img
                  :style="styles.strongDecoInnerImg"
                  :src="strongDecoInner.backgroundImage"
                >
              </div>
            </div>
            <span
              :class="{'active': getSelectStatus(tab, index)}"
              class="ui-tab-bar__item-inner-text"
            >
              {{ tab[textKey] }}
            </span>
          </div>
          <span
            v-if="(typeof tab[numberKey] === 'number'||tab[numberKey]) && showNumberComputed"
            :style="{...styles.tabNumber, marginBottom: mode === 'center' ? calcNum(11) : calcNum(8)}"
          >
            {{ tab[numberKey] }}
          </span>
        </div>
        <slot name="append" />
      </div>
    </div>
  </div>
</template>

<script>
import imageLineBg from './img/ui-tab-line-bg2.png';
import imageBtnBg from './img/ui-tab-btn-bg.png';

const styles = {
  tabOuter: { height: '44px', display: 'flex' },
  tabContainer: { flex: 1, flexDirection: 'row' },
  tabInner: {
    paddingLeft: '18px', paddingRight: '18px', display: 'flex', flexDirection: 'row', alignItems: 'center',
  },
  tabItemContainer: {
    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
  },
  strongDecoInnerImg: {
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  tabNumber: {
    alignSelf: 'flex-end', marginBottom: 8, fontSize: 11, color: '#b2b2b2', lineHeight: '15px', marginLeft: 'px', /* backgroundColor: 'green' */
  },
  level3Bg: {
    position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, borderRadius: '12.5px',
  },
};
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    // tab等级 1 2 3
    level: {
      type: Number,
      default: 2,
    },
    // 模式 默认为等距分布 average为均匀分布 center 等距居中
    mode: {
      type: String,
      default: 'equal',
    },
    splitWidth: {
      type: Number,
      default: 21,
    },
    // 是否显示数字
    showNumber: {
      type: Boolean,
      default: true,
    },
    borderBottomWidth: {
      type: Number,
      default: 0.5,
    },
    borderBottomColor: {
      type: String,
      default: '#e4e4e4',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    textKey: {
      type: String,
      default: 'text',
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    numberKey: {
      type: String,
      default: 'num',
    },
    tabList: {
      type: Array,
      default() {
        return [];
      },
    },
    showsHorizontalScrollIndicator: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      styles,
      imageBtnBg,
    };
  },
  computed: {
    modeComputed() {
      let mode = 'equal';
      if (this.level === 1) {
        mode = 'equal';
      } else if (this.level === 2) {
        // eslint-disable-next-line prefer-destructuring
        mode = this.mode;
      }
      return mode;
    },
    heightComputed() {
      if (this.level === 1 || this.level === 2) {
        return '44px';
      }
      return '50px';
    },
    splitWidthComputed() {
      if (this.level === 3) {
        return '10px';
      }
      return `${this.splitWidth}px`;
    },
    borderBottomWidthComputed() {
      if (this.level === 3) {
        return 0;
      } if (this.level === 2 && this.mode === 'center') {
        return 0;
      }
      return this.borderBottomWidth;
    },
    backgroundColorComputed() {
      if (this.level === 3) {
        return '#f7f7f7';
      }
      return this.backgroundColor;
    },
    showNumberComputed() {
      let show = '';
      if (this.level === 1) {
        show = false;
      } else if (this.level === 2) {
        show = this.showNumber;
      }
      return show;
    },
    strongDecoContainer() {
      let style = {};
      const style1 = {
        position: 'absolute', bottom: 0, left: 0, right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', overflow: 'hidden',
      };
      if (this.level === 1) {
        style = style1;
      } else if (this.level === 2) {
        style = style1;
      } else {
        style = { display: 'none' };
      }
      return style;
    },
    strongDecoInner() {
      const style = {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundImage: imageLineBg,
        overflow: 'hidden',
      };
      style.height = '4px';
      if (this.level === 1) {
        style.flex = 1;
        style.borderRadius = '2px';
      } else if (this.level === 2) {
        style.width = '40px';
        style.borderRadius = '2px';
      }
      style.transform = [{
        translateY: -0.5,
      }];
      return style;
    },
    justifyContentComputed() {
      const modeObj = {
        equal: 'flex-start',
        center: 'center',
      };
      return modeObj[this.modeComputed] ?? 'space-between';
    },
  },
  methods: {
    getMarginRight(index) {
      const { modeComputed, tabList, splitWidthComputed } = this;
      if (modeComputed === 'equal' || modeComputed === 'center') {
        return index === tabList.length - 1 ? 0 : splitWidthComputed;
      }
      return 0;
    },
    calcNum(num) {
      return `${num}px`;
    },
    getSelectStatus(tab, index) {
      return tab[this.valueKey] === this.value || (this.value === '' && index === 0);
    },
    getTextSize(tab, index) {
      let size = 'sl';
      if (this.getSelectStatus(tab, index)) {
        if (this.level === 1) {
          size = 'xl';
        } else if (this.level === 3) {
          size = 'xs';
        } else {
          size = 'l';
        }
      } else if (this.level === 3) {
        size = 'xs';
      } else {
        size = 'sl';
      }
      return size;
    },
    createTextStyle(tab, index) {
      const style = { lineHeight: '25px' };
      if (this.level === 2) {
        style.paddingTop = '10px';
      }
      if (this.getSelectStatus(tab, index)) {
        style.fontWeight = 600;
        style.color = '#303133';
      } else {
        style.fontWeight = 400;
        style.color = '#4c4c4c';
      }
      return style;
    },
    createTabItemInnerStyle(tab, index) {
      let style = {};
      if (this.level === 3) {
        style = {
          position: 'relative',
          height: this.calcNum(25),
          paddingLeft: this.calcNum(13),
          paddingRight: this.calcNum(13),
          backgroundColor: this.getSelectStatus(tab, index) ? '#00000000' : '#fff',
          borderRadius: this.calcNum(13),
        };
      } else {
        style = {
          position: 'relative',
          flexDirection: 'column',
          height: this.heightComputed,
          paddingBottom: this.calcNum(8),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        };
        style.paddingLeft = this.calcNum(2);
        style.paddingRight = this.calcNum(2);
        if (this.level === 2 && this.mode !== 'center') {
          style.paddingBottom = this.calcNum(7);
        }
      }
      return style;
    },
    onTabChange(tab, index) {
      // 加入100ms时间间隔不允许短时间内点两个
      if (!this.changeSplitLimit) {
        this.$emit('change', tab[this.valueKey]);
        this.$emit('tabChange', tab[this.valueKey], tab, index);
        this.changeSplitLimit = true;
        setTimeout(() => {
          this.changeSplitLimit = false;
        }, 100);
      }
    },
    onScroll(e) {
      this.$emit('scroll', e);
    },
    onItemLayout(e, index) {
      this.$emit('itemLayout', e, index);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
