<!-- eslint-disable vue/valid-template-root -->
<template>
  <PostLoading v-if="pageLoading" />
  <PostError
    v-else-if="pageError"
    :custom-header="false"
    :text="pageError"
  />
  <ScrollView
    v-else
    :loading="loadingStatus"
    :error="pageError"
    :no-more="hasMore===0"
    :bottom-distance="0"
    :no-more-text="historyList.length > 5 ? '没有更多内容了' : ''"
    @reachBottom="getPageData"
    class="page-ftf-history"
  >
    <li
      v-for="item in historyList"
      :key="item.activityId"
      @click="jumpToDetail(item)"
    >
      <div
        class="page-ftf-history__img"
      >
        <img :src="item.iconUrl">
      </div>
      <div class="page-ftf-history__info">
        <h3 class="page-ftf-history__title">
          {{ item.title }}
        </h3>
        <div class="page-ftf-history__time">
          <span>{{ formatActivityTime(item.beginTime, item.endTime) }}</span>
          <i
            :class="activityStatusClass[item.curStatus]"
            class="page-ftf-history__status"
          />
        </div>
      </div>
    </li>
  </ScrollView>
</template>

<script>
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import ScrollView from '@/ui/scroll-view/index.vue';
import { getFTFHistory } from '@/api/face-to-face';
import { openView } from '@/jsbridge';
import reportApi from '@/api/report';

export default {
  name: 'FaceToFaceHistory',
  components: {
    PostLoading,
    PostError,
    ScrollView,
  },
  data() {
    return {
      pageLoading: true,
      pageError: false,
      hasMore: 1,
      nextTimeLine: 0,
      historyList: [],
      loadingStatus: false,
      activityStatusClass: {
        0: '',
        1: '',
        2: 'status-ing',
        3: 'status-finished',
      },
    };
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    getPageData() {
      if (!this.hasMore || this.loadingStatus) {
        return;
      }
      this.loadingStatus = true;
      getFTFHistory({
        time_line: this.nextTimeLine,
      })
        .then((result) => {
          this.pageLoading = false;
          this.loadingStatus = false;
          if (result.ret === 0) {
            this.nextTimeLine = result.nextTimeLine;
            this.hasMore = result.hasMore;
            this.historyList = [
              ...this.historyList,
              ...(result.infoList || []),
            ];
            this.reportData({
              pageid: '1508',
              moduleid: 1,
              eventtype: 1,
              extendid: 2,
            }, '策划面对面往期页面PV');
          } else {
            this.pageError = result.msg || '服务器繁忙，请稍后重试';
          }
        })
        .catch(() => {
          this.pageLoading = false;
          this.pageError = '服务器繁忙，请稍后重试';
        });
    },
    formatActivityTime(startTime = 0, endTime = 0) {
      const startDate = new Date(startTime * 1000);
      const endDate = new Date(endTime * 1000);
      const needShowYear = startDate.getFullYear() !== new Date().getFullYear();
      let startHours = startDate.getHours();
      let endHours = endDate.getHours();
      let startMinutes = startDate.getMinutes();
      let endMinutes = endDate.getMinutes();
      startHours = startHours < 10 ? `0${startHours}` : startHours;
      endHours = endHours < 10 ? `0${endHours}` : endHours;
      startMinutes = startMinutes < 10 ? `0${startMinutes}` : startMinutes;
      endMinutes = endMinutes < 10 ? `0${endMinutes}` : endMinutes;
      return `${
        needShowYear ? `${startDate.getFullYear()}年 ` : ''
      }${startDate.getMonth()
        + 1}月${startDate.getDate()}日 ${startHours}:${startMinutes} ~ ${endHours}:${endMinutes}`;
    },
    jumpToDetail(item) {
      this.reportData({
        pageid: '1508',
        moduleid: 1,
        eventtype: 3,
        extendid: 3,
      }, '策划面对面往期页面内容点击');
      openView({ options: `tgc://qa_live/home?activityId=${item.activityId}` });
    },
    // 数据上报
    reportData(params, desc) {
      return reportApi(params, desc);
    },
  },
};
</script>

<style lang="scss" src="./scss/history.scss"></style>
