/**
 * 策划面对面API
 * */
import { gameplusApi } from '@/util/http';
// import ftfHistory from '../mock/ftfHistory.json';
// import ftfAnswerList from '../mock/ftfAnswerList.json';

function getActivityInfo({ activityId } = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetActivityInfo',
    data: {
      activity_id: activityId,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfHistory,
  });
}

function getActivityList({ timeLine, count } = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetActivityList',
    data: {
      time_line: timeLine,
      count,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfHistory,
  });
}

function getQuestionList({
  userId = 0, qaLiveId, type = 2, context = '', look = 1,
} = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetQuestionList',
    data: {
      user_id: userId,
      qa_live_id: qaLiveId,
      type,
      look,
      context,
    },
    options: {
      errorHandler: false,
    },
  });
}

function getAnswerList({
  userId = 0, qaLiveId, sortType = 2, context = '',
} = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetAnswerList',
    data: {
      user_id: userId,
      qa_live_id: qaLiveId,
      sort_type: sortType,
      context,
    },
    options: {
      errorHandler: false,
    },
  });
}

// /**
//    * 拉取往期面对面列表
//    * @param { Object } params
//    * @param {
//      Number
//    }
//    params.timeLine 时间线
//    * @param {
//      Number
//    }
//    params.count 数量
//    * @return { Object } 返回获取结果
//    * */
function getFTFHistory({ timeLine = 0, count = 20 } = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetActivityList',
    data: {
      time_line: Number(timeLine),
      count: Number(count),
    },
    options: {
      errorHandler: false,
    },
  });
}

function getAnswerListByAskID({
  userId, qaLiveId, askId, sortType, context,
} = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetAnswerListByAskID',
    data: {
      user_id: Number(userId),
      qa_live_id: Number(qaLiveId),
      ask_id: Number(askId),
      sort_type: Number(sortType),
      context,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfAnswerList,
  });
}

function addPraise({
  userId, qaLiveId, answerId, praiseType,
} = {}) {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/AddPraise',
    data: {
      user_id: Number(userId),
      qa_live_id: Number(qaLiveId),
      answer_id: Number(answerId),
      praise_type: praiseType,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfAnswerList,
  });
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getActivityInfo,
  getActivityList,
  getQuestionList,
  getAnswerList,
  getFTFHistory,
  getAnswerListByAskID,
  addPraise,
};
