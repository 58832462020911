<template>
  <div class="pc-ftf-answer-detail">
    <div
      v-if="qaData.isSharp"
      class="pc-ftf-answer-detail__sharp"
    />
    <Avatar
      :size="'s'"
      :avatar="avatarObj.iconUrl"
      :account-type="avatarObj.accountType"
      :vip-type="avatarObj.vipType"
      @click.native.stop="onAvatarClick"
      class="pc-ftf-answer-detail__avatar"
    />
    <div class="pc-ftf-answer-detail__cont">
      <div class="pc-ftf-answer-detail__cont-name">
        <div
          v-if="type === 'answer'"
          @click="onUserNameClick"
          class="pc-ftf-answer-detail__cont-name--inner"
        >
          <div class="name-text ui-nowrap">
            {{ avatarObj.nickName }}
          </div>
          <div
            v-if="Number(qaData.replyType) === 1"
            class="name-label"
          >
            主持人
          </div>
        </div>
        <div
          v-if="type === 'question'"
          @click="onUserNameClick"
          class="pc-ftf-answer-detail__cont-name--inner"
        >
          <div class="name-text ui-nowrap">
            {{ avatarObj.nickName }}
          </div>
          <LabelAuthorRank
            v-show="avatarObj.level > 0"
            :level="avatarObj.level"
            class="name-deco"
          />
          <PrestigeIcon
            v-if="avatarObj.renownInfo && avatarObj.renownInfo.iconUrl"
            :icon-url="avatarObj.renownInfo.iconUrl"
            class="name-deco"
          />
          <OnlineStatus
            :status="avatarObj.onlineStatus"
            :game-code="avatarObj.extend && avatarObj.extend.teamGameCode"
            class="name-deco"
          />
        </div>
        <div
          v-if="type === 'question'"
          class="pc-ftf-answer-detail__cont-name-time"
        >
          {{ qaData.createTimestamp | formatTime() }}
        </div>
      </div>
      <div
        v-if="type === 'answer' && qaData.asker"
        class="pc-ftf-answer-detail__cont-answer"
      >
        <span class="text-name">{{ qaData.asker.nickName }}：</span>
        <span class="text-normal">{{ qaData.questionText }}</span>
      </div>
      <div class="pc-ftf-answer-detail__cont-reply">
        <span
          v-if="type === 'answer' && qaData.isTop === true"
          class="pc-ftf-answer-detail__cont-reply-top"
        />{{ qaData.text }}
      </div>
      <div
        v-if="qaData.voiceInfo && Array.isArray(qaData.voiceInfo) && qaData.voiceInfo.length > 0"
        class="pc-ftf-answer-detail__cont-audio"
      >
        <AudioItem
          v-for="(item, index) in qaData.voiceInfo"
          :key="index"
          :audio-id="`answer${qaData.answerId}audio${index}`"
          :audio-src="item.url"
          :audio-length="Number(item.duration)"
          @addAudioObj="onAddAudioObj"
          @audioPlay="onAudioPlay"
          @audioClick="onAudioClick"
          class="pc-ftf-answer-detail__cont-audio-item"
        />
      </div>
      <!--<div v-if="qaData.voiceInfo && Array.isArray(qaData.voiceInfo)"
      class="pc-ftf-answer-detail__cont-audio"
    >
      <div class="audio-operate"
        :class="{'pause': isPlay,'start': !isPlay}"
      />
      <div class="audio-wave" />
      <span class="audio-time">30"</span>
    </div>-->
      <div
        v-if="qaData.imageInfo && Array.isArray(qaData.imageInfo) && qaData.imageInfo.length > 0"
        @click="onPicClick"
        class="pc-ftf-answer-detail__cont-pic"
      >
        <ul class="pc-ftf-answer-detail__cont-pic-list">
          <li
            v-for="(item, i) in new Array(3)"
            :key="qaData.answerId + i"
            class="pic-item"
          >
            <div
              v-if="qaData.imageInfo[i]"
              class="pic-item-inner"
            >
              <span
                :style="{backgroundImage: `url(${qaData.imageInfo[i]['url']})`}"
                class="img-cover"
              />
            </div>
          </li>
        </ul>
        <div
          v-if="qaData.imageInfo.length > 3"
          class="pc-ftf-answer-detail__cont-pic-more"
        >
          <i class="more-pic" />
          <span class="more-num">{{ qaData.imageInfo.length }}</span>
        </div>
      </div>
      <div
        v-if="type === 'answer'"
        class="pc-ftf-answer-detail__cont-other"
      >
        <div class="other-time">
          {{ qaData.createTime | formatTime() }}
        </div>
        <div
          @click="onLikeClick"
          class="other-like"
        >
          <Icon
            :active="isLike"
            :name="'applaud'"
            :size="'s'"
            class="other-like-icon"
          />
          <span class="other-like-text" />
        </div>
      </div>
      <div
        v-if="type === 'question'"
        class="pc-ftf-answer-detail__cont-agree"
      >
        <div class="agree-number">
          {{ qaData.askNum }}人同问
        </div>
        <Button
          :type="'primary'"
          :size="'s'"
          class="agree-btn"
        >
          <span class="agree-btn-up" />
          <span
            @click="onAgreeClick"
            class="agree-btn-text"
          >同问</span>
        </Button>
      </div>
      <div
        v-if="isShowDesigner"
        class="pc-ftf-answer-detail__cont-designer"
      >
        <div
          @click="onReplyUserClick"
          class="pc-ftf-answer-detail__cont-designer-container"
        >
          <div class="designer-avatar">
            <div
              v-for="(item, i) in qaData.replyersInfo.replyers"
              :key="i"
              class="designer-avatar-item"
            >
              <Avatar
                :size="'s'"
                :avatar="item.iconUrl"
                :account-type="item.accountType"
                :vip-type="item.vipType"
              />
            </div>
          </div>
          <div class="designer-cont">
            <div class="designer-cont-name ui-nowrap">
              {{ qaData.replyersInfo.nameDesc }}
            </div>
            <div class="designer-cont-desc ui-nowrap">
              回答了该问题
            </div>
          </div>
          <div class="designer-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/ui/icon/index';
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button/index.vue';
import LabelAuthorRank from '@/ui/label/author-rank.vue';
import PrestigeIcon from '@/ui/label/prestige-icon.vue';
import OnlineStatus from '@/ui/label/online-status.vue';
import AudioItem from '../ftf-audio/index.vue';

export default {
  components: {
    Icon,
    Avatar,
    Button,
    LabelAuthorRank,
    PrestigeIcon,
    OnlineStatus,
    AudioItem,
  },
  filters: {
    formatTime(time) {
      const numberTime = Number(time);
      const nowTime = new Date().getTime();
      if (numberTime > 0) {
        let str = '';
        if (nowTime - numberTime > 365 * 24 * 60 * 60 * 1000) {
          str = 'Y-M-D h:m';
        } else if (nowTime - numberTime > 24 * 60 * 60 * 1000) {
          str = 'M-D h:m';
        } else {
          str = 'h:m';
        }
        const oDate = new Date(numberTime * 1000);
        const Y = oDate.getFullYear();
        let M = oDate.getMonth() + 1;
        let D = oDate.getDate();
        let h = oDate.getHours();
        let m = oDate.getMinutes();
        let s = oDate.getSeconds();
        M = M < 10 ? `0${M}` : M;
        D = D < 10 ? `0${D}` : D;
        h = h < 10 ? `0${h}` : h;
        m = m < 10 ? `0${m}` : m;
        s = s < 10 ? `0${s}` : s;
        return str.replace(/(Y|M|D|h|m|s)/g, t => ({
          Y, M, D, h, m, s,
        }[t]));
      }
      return '';
    },
  },
  props: {
    type: {
      type: String,
      default: 'answer',
    },
    hostUserId: {
      type: Number,
      default: 1,
    },
    qaData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isLike: false,
      isPlay: false,
    };
  },
  computed: {
    avatarObj() {
      if (this.type === 'answer') {
        return {
          ...this.qaData.replyer,
        };
      } if (this.type === 'question') {
        return {
          ...this.qaData.asker,
        };
      }
      return {};
    },
    isShowDesigner() {
      const { type, qaData } = this;
      return type === 'question' && qaData.replyersInfo && Array.isArray(qaData.replyersInfo.replyers) && qaData.replyersInfo.replyers.length > 0;
    },
  },
  methods: {
    onAvatarClick() {
      this.$emit('resumeAppActivity');
    },
    onUserNameClick() {
      this.$emit('resumeAppActivity');
    },
    onPicClick() {
      this.$emit('resumeAppActivity');
    },
    onLikeClick() {
      this.$emit('resumeAppActivity');
    },
    onAgreeClick() {
      this.$emit('agreeClick');
    },
    onReplyUserClick() {
      this.$emit('resumeAppActivity');
    },
    onAddAudioObj(obj) {
      this.$emit('addAudioObj', obj);
    },
    onAudioPlay(audioId) {
      this.$emit('audioPlay', audioId);
    },
    onAudioClick(audioId) {
      this.$emit('audioClick', audioId);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
